import React, { Suspense, lazy } from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled/macro"
import { useCommonItemsQuery } from "../../../hooks/use-common-items-query"
import { Layout, Theme2, Device, Container } from "../../../components/Layout"
import { Seo } from "../../../components/Seo"
import { HeaderMobile } from "../../../components/HeaderMobile"
import { HeaderDesktop } from "../../../components/HeaderDesktop"
import { HeroInterior } from "../../../components/HeroInterior"
import { SidebarForm } from "../../../components/SidebarForm"
import { InteriorContent } from "../../../components/InteriorContent"
import { ResultsInterior } from "../../../components/ResultsInterior"
import { GeneralSchema } from "@ashleynexvelsolutions/general-schema"
import { PageSpecificSchema } from "@ashleynexvelsolutions/page-specific-schema"
import {
  GeneralSchemaFunction,
  PageSpecificSchemaFunction,
} from "../../../DataImports"

const InteriorVideoSection = lazy(() =>
  import("../../../components/InteriorVideoSection").then(module => ({
    default: module.InteriorVideoSection,
  }))
)
const SidebarMenu = lazy(() =>
  import("../../../components/SidebarMenu").then(module => ({
    default: module.SidebarMenu,
  }))
)
const InteriorCaseStudiesNonPracticeAreas = lazy(() =>
  import("../../../components/InteriorCaseStudiesNonPracticeAreas").then(
    module => ({
      default: module.InteriorCaseStudiesNonPracticeAreas,
    })
  )
)
const InteriorPageCallout = lazy(() =>
  import("../../../components/InteriorPageCallout").then(module => ({
    default: module.InteriorPageCallout,
  }))
)
const VideoCarousel = lazy(() =>
  import("../../../components/VideoCarousel").then(module => ({
    default: module.VideoCarousel,
  }))
)
const Awards = lazy(() =>
  import("../../../components/Awards").then(module => ({
    default: module.Awards,
  }))
)
const Footer = lazy(() =>
  import("../../../components/Footer").then(module => ({
    default: module.Footer,
  }))
)

const InteriorPageContainer = styled.div`
  display: grid;

  main {
    margin: 5em 0 2em 0;
  }

  @media ${Device.lg} {
    max-width: 1400px;
    margin: 0 auto;
    grid-template-columns: 405px auto;
    grid-column-gap: 10%;
    aside {
      grid-column: 1;
      grid-row: 1;
      margin-top: -4.5em;
      z-index: 1;
    }
    main {
      margin: 5em 0 7em 0;
    }
  }
  .title {
    font-size: 25px;
    line-height: 1.1;
    display: inline-block;
    font-family: ${Theme2.fonts.headings};
    margin: 0.75em 0;
    font-weight: 700;
    @media ${Device.md} {
      font-size: calc(25px + 9 * ((100vw - 768px) / 432));
    }
    @media ${Device.xl} {
      font-size: 34px;
    }
  }
  input,
  textarea {
    resize: none;
    width: 100%;
    box-sizing: border-box;
    background-color: ${Theme2.colors.blogBackgroundColor};
    border: 1px solid ${Theme2.colors.neutral};
    border-radius: 2px;
    padding: 0.5rem;

    @media ${Device.lg} {
      padding: 1rem;
      border-radius: 5px;
    }
  }
  textarea {
    @media ${Device.lg} {
      grid-column: 1 / span 2;
    }
  }
  .formFieldsGridContainer {
    display: grid;
    grid-gap: 7px;

    @media ${Device.lg} {
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
  }
  .error:empty {
    display: none;
  }
  .contactFormButton {
    margin: 20px 0;
    appearance: none;
    border: none;
    background-color: ${Theme2.colors.tertiary};
    color: ${Theme2.colors.light};
    padding: 10px;
    border-radius: 5px;

    p {
      margin: 0;
    }

    &:hover {
      background-color: ${Theme2.colors.tertiaryDarker};
      cursor: pointer;
    }
  }
  .officeTitles {
    font-weight: 500;
    margin-bottom: 0.75em;
  }
  .officeInfoOuterContainer {
    @media ${Device.lg} {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    a {
      color: ${Theme2.colors.dark1};
      display: block;
      margin-bottom: 0.75rem;

      address {
        font-style: normal;
      }
      &:hover {
        color: ${Theme2.colors.tertiary};
      }
    }
  }
  .bloodAlcoholCalculator {
    margin-top: 1rem;
    height: 990px;

    @media ${Device.xl} {
      height: 790px;
    }
  }
`
const CaseResultsContainer = styled.div`
  background-color: ${Theme2.colors.tertiary};
  padding: 50px 0;
  color: ${Theme2.colors.light};
  text-align: center;
`
const CaseResultsTitle = styled.div`
  margin-bottom: 30px;
  font-size: 38px;
  line-height: 38px;
  font-family: ${Theme2.fonts.alternateHeadings};

  hr {
    width: 40px;
    height: 5px;
    background-color: ${Theme2.colors.light};
    border: none;
  }
`

const BloodAlcoholCalculator = ({ data }) => {
  const featuredImage =
    data.wpPage.featuredImage !== null
      ? data.wpPage.featuredImage.node.localFile.childImageSharp.gatsbyImageData
          .images.sources[1].srcSet
      : ``

  const {
    commonItemsCaseResultSidebarHeading,
    commonItemsPageSubtitle,
    commonItemsInteriorVideoTitle,
    commonItemsInteriorVideoSubtitle,
  } = useCommonItemsQuery()
  const seo = data.wpPage.seo
  return (
    <>
      <GeneralSchema {...GeneralSchemaFunction()} />
      <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />

      <Seo data={seo} />
      <Layout>
        <HeaderMobile />
        <HeaderDesktop />
        <HeroInterior
          isItNull={data.wpPage.featuredImage}
          image={featuredImage}
          alt={data.wpPage.interiorpage.bannerSectionImageAltText}
          title={data.wpPage.title}
          subHeading={commonItemsPageSubtitle}
        />

        <Container>
          <InteriorPageContainer>
            <main>
              <InteriorContent
                content={data.wpPage.interiorpage.contentSection1}
              />

              {data.wpPage.interiorpage.contentSection2 && (
                <>
                  <Suspense fallback="loading">
                    <InteriorVideoSection
                      altText={
                        data.wpPage.interiorpage.interiorVideoImageAltText
                      }
                      title={commonItemsInteriorVideoTitle}
                      subtitle={commonItemsInteriorVideoSubtitle}
                    />
                  </Suspense>

                  <InteriorContent
                    content={data.wpPage.interiorpage.contentSection2}
                  />
                </>
              )}

              <iframe
                className="bloodAlcoholCalculator"
                src="https://app.calconic.com/api/embed/calculator/611bd160db5cac0021b82129"
                sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups-to-escape-sandbox allow-popups"
                title="Calconic_ Calculator"
                name="Calconic_ Calculator"
                scrolling="no"
                style={{ width: "100%", border: "0", outline: "none" }}
              />
              <Suspense fallback="loading">
                <InteriorPageCallout
                  calloutImageAltText={
                    data.wpPage.interiorpage.calloutImageAltText
                  }
                />
              </Suspense>
            </main>
            <aside>
              <SidebarForm />

              {data.wpPage.categories.nodes.length > 0 ? (
                <CaseResultsContainer>
                  <CaseResultsTitle>
                    {commonItemsCaseResultSidebarHeading}
                    <hr />
                  </CaseResultsTitle>

                  <ResultsInterior
                    data2={data.wpPage.categories.nodes[0].slug}
                  />
                </CaseResultsContainer>
              ) : (
                <Suspense fallback="loading">
                  <InteriorCaseStudiesNonPracticeAreas />
                </Suspense>
              )}
              <Suspense fallback="loading">
                <SidebarMenu />
              </Suspense>
            </aside>
          </InteriorPageContainer>
        </Container>
        <Suspense fallback="loading">
          <VideoCarousel hasH1={false} />
        </Suspense>
        <Suspense fallback="loading">
          <Awards />
        </Suspense>
        <Suspense fallback="loading">
          <Footer />
        </Suspense>
      </Layout>
    </>
  )
}
export const query = graphql`
  query {
    wpPage(title: { eq: "Blood Alcohol Calculator" }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphSiteName
        opengraphType
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        canonical
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
      nexvelschemapagesposts {
        videos {
          title
          url
          description
          thumbnailImage {
            localFile {
              publicURL
            }
          }
          uploadDate
        }
        questionsAndAnswers {
          question
          answer
        }
        maps {
          mapUrl
        }
        digitaldocuments {
          title
        }
        images {
          image {
            localFile {
              publicURL
            }
            date(formatString: "LL")
            description
            title
          }
        }
        areaServedLandingPageSchema
        serviceSchema {
          serviceNameSchema
          linkToRelevantServicePageSchema
        }
        keywordsSchema {
          keywordSchema
        }
        localAwardsSchema {
          localAwardSchema
        }
        latitudeSchema
        longitudeSchema
        relevantExternalPagesSchema {
          relevantExternalPageSchema
        }
      }
      interiorpage {
        bannerSectionImageAltText
        contentSection1
        interiorVideoImageAltText
        calloutImageAltText
        contentSection2
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
      title
      categories {
        nodes {
          slug
        }
      }
    }
    file(relativePath: { eq: "interior-cta-background.jpg" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], layout: FULL_WIDTH)
      }
    }
    wp {
      nexvelSchemaMarkup {
        nexvelschema {
          cities {
            city
          }
          services {
            service
            linkToRelevantPageOnYourWebsite
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default BloodAlcoholCalculator
